/* unplugin-vue-components disabled */import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-79a88ff8"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["id"];
export default {
  __name: 'block-wrapper',
  props: {
    element: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    provide('block-wrapper', props.element);
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        id: __props.element.id,
        ref: "blockWrapper",
        style: _normalizeStyle({
          height: __props.element.height,
          width: __props.element.width,
          left: __props.element.x,
          top: __props.element.y,
          zIndex: __props.element.z
        }),
        class: "block-wrapper"
      }, [_renderSlot(_ctx.$slots, "default")], 12, _hoisted_1);
    };
  }
};